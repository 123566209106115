import { useHandler } from './handler_provider'
import React, { useEffect, useState } from 'react'
import trucount320px from './images/trucount320px.png'
import trucarbon320px from './images/trucarbon320px.png'
import exit_ from './images/exit.svg'
import { Link } from 'react-router-dom'

export function Home() {
  const handler = useHandler()
  const [userInfo, setUserInfo] = useState()
  useEffect(() => {
    handler.requestUserInfoLookup()
    handler.subToUserInfo(setUserInfo)
    return () => handler.unsubFromUserInfo(setUserInfo)
  }, [handler])

  return <div id="parent" className={[
    'min-h-screen',
    'h-max',
    'flex',
    'flex-col',
    'md:justify-center',
    'md:items-center',
    'flex-wrap',
  ].join(' ')}>
    <div className="flex flex-col p-6 lg:p-3 gap-3">
      <div className="trakcing-wide text-4xl text-left">
        Hi <div className="inline-block font-semibold">
          {userInfo?.name?.replace(/\s.*$/, '')}
          &nbsp;
          <span className="inline-block hover:animate-[wave_2s_linear_infinite]">
            👋🏼
          </span>
        </div>
      </div>
      <div id="child2" className={[
        'tracking-wide',
        'font-semibold',
        'grid',
        'grid-cols-2',
        'md:grid-cols-3',
        'gap-3',
        'justify-center',
        'items-center',
      ].join(' ')}>
        <Link
          className={[
            'border',
            'p-7',
            'border-black',
            'rounded-2xl',
            'aspect-square',
            'flex',
            'items-center',
            'justify-center',
            'transition-all',
            'shadow-md',
            'hover:shadow-xl',
            'hover:-translate-y-2',
            'grayscale',
            'hover:grayscale-0',
          ].join(' ')}
          to={process.env.REACT_APP_TRUCOUNT_URL}
        >
          <img className="md:max-h-16" src={trucount320px} alt="trucount" />
        </Link>
        <Link
          className={[
            'border',
            'p-7',
            'border-black',
            'rounded-2xl',
            'aspect-square',
            'flex',
            'items-center',
            'justify-center',
            'transition-all',
            'shadow-md',
            'hover:shadow-xl',
            'hover:-translate-y-2',
            'grayscale',
            'hover:grayscale-0',
          ].join(' ')}
          to={process.env.REACT_APP_TRUCARBON_URL}
        >
          <img className="md:max-h-16" src={trucarbon320px} alt="trucarbon" />
        </Link>
        <button className={[
          'border',
          'p-7',
          'border-black',
          'rounded-2xl',
          'aspect-square',
          'flex',
          'items-center',
          'justify-center',
          'transition-all',
          'shadow-md',
          'hover:shadow-xl',
          'hover:-translate-y-2',
          'grayscale',
          'hover:grayscale-0',
        ].join(' ')}
          onClick={handler.requestUserTokenDisposal}>
          <img className="max-h-28 md:max-h-20 -scale-x-100" src={exit_} alt="exit" />
        </button>
      </div>
    </div>
  </div>
}

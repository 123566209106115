import ReactDOM from 'react-dom/client'
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom'
import { HandlerProvider, useHandler } from './handler_provider'
import './index.css'
import Login from './login'
import CxrLogin from './cxr_login'
import NotFound from './not_found'
import Register from './register'
import ForgotPassword from './forgot_password'
import reportWebVitals from './reportWebVitals'
import Root from './root'
import { useEffect, useState } from 'react'
import { Home } from './home'

function React(handler) {
  const router = createBrowserRouter([{
    path: '/',
    element: <Root />,
    children: [
      {
        element: <Public />,
        children: [
          { path: '/login', element: <Login /> },
          { path: '/cxr-login', element: <CxrLogin /> },
          { path: '/register', element: <Register /> },
          { path: '/forgot-password', element: <ForgotPassword /> }
        ],
      },
      {
        element: <Private />,
        children: [
          { index: true, element: <Home /> },
        ],
      },
      { path: '*', element: <NotFound /> },
    ],
  }])

  async function start() {
    ReactDOM
      .createRoot(document.getElementById('root'))
      .render(<HandlerProvider handler={handler} >
        <RouterProvider router={router} />
      </HandlerProvider>)

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  }

  return { start }
}

function Public() {
  const handler = useHandler()
  const [signedIn, setSignedIn] = useState('waiting')
  useEffect(() => {
    handler.subToSignedIn(setSignedIn)
    return () => handler.unsubFromSignedIn(setSignedIn)
  }, [handler])

  if (signedIn === 'waiting') {
    return <></>
  } else if (signedIn) {
    return <Navigate to="/" replace={true} />
  } else {
    return <Outlet />
  }
}

function Private() {
  const handler = useHandler()
  const [signedIn, setSignedIn] = useState('waiting')
  useEffect(() => {
    handler.subToSignedIn(setSignedIn)
    return () => handler.unsubFromSignedIn(setSignedIn)
  }, [handler])
  const location = useLocation()

  if (signedIn === 'waiting') {
    return <></>
  } else if (!signedIn) {
    return <Navigate
      to={`/login?redirect=${location.pathname}`}
      replace={true}
    />
  } else {
    return <Outlet />
  }
}

export default React

import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useHandler } from './handler_provider'
import logo from './images/dark-logo.webp'

export default function Login() {
  const handler = useHandler()
  const [lexicon, setLexicon] = useState({})
  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const toSignupLocation = location.search ? `/register${location.search}` : '/register'

  return (
    <StyleWrapper>
      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>

          <div className="shape">
            <svg
              xmlns="http:www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1440 120"
            >
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
          </div>
        </div>

        <div className="auth-page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img className="max-h-5" src={logo} alt="" height="20" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary text-base">
                        {lexicon.welcome_to_tru_climate}
                      </h5>
                      <p className="text-muted text-base">
                        {lexicon.sign_in_to_continue}
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        layout="vertical"
                        name="loginForm"
                        className="login-form"
                        onFinish={(values) => {
                          handler.requestUserTokenRenewal({
                            email: values.email,
                            password: values.password,
                            ...(searchParams.get('redirect_') && {
                              redirect_: searchParams.get('redirect_'),
                            }),
                            ...(searchParams.get('redirect') && {
                              redirect: searchParams.get('redirect'),
                            }),
                          })
                        }}
                      >
                        <Form.Item
                          label={lexicon.email}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your Email',
                            },
                            {
                              type: 'email',
                              message: 'Invalid Email',
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <MailOutlined
                                style={{ color: 'lightGray' }}
                                className="site-form-item-icon"
                              />
                            }
                            placeholder={lexicon.email}
                          />
                        </Form.Item>
                        <Form.Item
                          className='mb-2'
                          label={lexicon.password}
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your Password',
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined
                                style={{ color: 'lightGray' }}
                                className="site-form-item-icon"
                              />
                            }
                            type="password"
                            placeholder={lexicon.password}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Link
                            to="/forgot-password"
                            className="fw-semibold text-primary text-decoration-underline"
                          >
                            {lexicon.forgot_password}
                          </Link>
                        </Form.Item>

                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button bg-[#3EB6AE]"
                            block
                          >
                            {lexicon.sign_in}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    {lexicon.dont_have_an_account}?{' '}
                    <Link
                      to={toSignupLocation}
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {lexicon.sign_up}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    &copy;&nbsp;{new Date().getFullYear()} TruClimate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </StyleWrapper>
  )
}

const StyleWrapper = styled.div`
  .custom-label {
    .ant-form-item-required {
      width: 100% ;
    }
  }
`

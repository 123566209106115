import Coordinator from './coordinator'
import React from './react'
import Server from './server'

const system = [
  React,
  Coordinator({
    url: process.env.REACT_APP_BASE_SERVER,
  }),
].reduceRight((previous, current) => {
  return current(previous)
}, Server({
  url: process.env.REACT_APP_BASE_SERVER,
}))

system.start()

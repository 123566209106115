import { useEffect, useState } from 'react'
import { useHandler } from './handler_provider'

function Loading() {
  const handler = useHandler()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const adjustLoading = (loading) => {
      const values = Object.values(loading)
      setLoading(values.length && values.some(value => !!value))
    }

    handler.subToLoading(adjustLoading)
    return () => { handler.unsubFromLoading(adjustLoading) }
  }, [handler])

  return <div className={[
    'fixed',
    'left-0',
    'top-0',
    'z-[99]',
    'h-full',
    'w-full',
    'flex',
    'flex-row',
    'justify-center',
    'items-center',
    'bg-white',
    'pointer-events-none',
    'transition-opacity',
    !loading ? 'opacity-0' : 'opacity-100',
  ].join(' ')}>
    {/* <div alt="" className={[
      'h-16',
      'aspect-square',
      'border-8',
      'border-[#3EB6AE]',
      'border-l-transparent',
      'rounded-full',
      'animate-spin',
    ].join(' ')} /> */}
    <lord-icon
      src="/assets/json/ylwqnedo.json"
      trigger="loop"
      colors="primary:#121331,secondary:#08a88a"
      style={{
        height: '8rem',
        width: '8rem',
      }}
    >
    </lord-icon>
  </div>
}

export default Loading
